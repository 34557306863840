import * as React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'

const navItems = [
  // { name: 'Home', to: '/' },
  // { name: 'Details', to: '/details/' },
  // { name: 'Timeline', to: '/timeline/' },
  // { name: 'Program', to: '/program/' },
  // { name: 'The Couple', to: '/couple/' },
  // { name: 'Wedding Party', to: '/wedding-party/' },
  // { name: 'Registry', to: 'https://www.zola.com/registry/wooriandandrew' },
  // { name: 'Activities', to: '/activities/' },
  // { name: 'Accommodations', to: '/accommodations/' },
  // { name: 'Travel', to: '/travel/' },
  // { name: 'FAQs', to: '/faq/' },

];

const Layout = ({ pageTitle, pageDescription, children }) => {

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }`)

  const [showMenu, setShowMenu] = React.useState(false);

  function isActive({ isCurrent }) {
    return isCurrent ? { className: "border-b-2 border-blush-accent" } : null
  }
  const description = pageDescription || "The wedding website of Woori Bae and Andrew Wong.";
  return (
    <div className={"m-auto pb-8 " + (showMenu ? "overflow-y-hidden h-screen" : "overflow-y-auto")}>
      <Helmet
        htmlAttributes={{ lang: 'en' }}>
        <meta charSet="utf-8" />
        <meta name="description" content={description} />
        <title>{pageTitle + ' | ' + data.site.siteMetadata.title}</title>
        <link rel="icon" type="image/png"
          href="/icons/icon-32x32.png" />
      </Helmet>
      {
        /* Mobile Nav.
         * This needs to be outside the main div otherwise it messes with scrolling
         * because the content and desktop menu needs to be overflow:auto with the rest of the
         * page being overflow:hidden;
         */
      }
      <nav className={
        "sm:hidden z-30 absolute top-0 left-0 m-auto " +
        "transform-gpu transition ease-out duration-200 " +
        (showMenu ?
          'translate-x-0 opacity-100 ' :
          '-translate-x-full opacity-0 ') +
        "flex flex-col " +
        "w-screen h-screen " +
        "bg-blush " +
        "justify-center items-center text-center " +
        ""}>
        {
          navItems.map((item) => (
            <div key={item.name} className="p-2 md:p-4 font-menu text-xl">
              {(item.to.includes("://") && <a href={item.to}>{item.name}</a>) || <Link to={item.to} getProps={isActive}>{item.name}</Link>}
            </div>
          ))
        }
      </nav>
      <div>
        <header className="m-auto pt-2 py-2 sm:pb-0 bg-blush z-40 relative">
          { /* Hamburger */}
          <button onClick={() => setShowMenu(!showMenu)}
            aria-label="Open menu"
            className="sm:hidden
                               absolute top-2.5 left-6 my-1.5
                               flex flex-col
                               justify-around w-6 h-6 bg-transparent
                               cursor-pointer z-50">
            <div className="bg-blush-accent relative h-0.5 w-6 rounded origin-center"></div>
            <div className="bg-blush-accent relative h-0.5 w-6 rounded origin-center"></div>
            <div className="bg-blush-accent relative h-0.5 w-6 rounded origin-center"></div>
          </button>
          <div className="flex flex-row justify-center sm:my-4">
            <Link to="/" className="">
              <span className="z-40 relative mx-auto block text-center text-4xl sm:text-6xl font-header text-gold px-8">
                Woori &amp; Andrew
              </span>
            </Link>
          </div>
          { /* Desktop Nav */}
          <nav className="hidden sm:flex sm:relative sm:flex-row justify-center items-center">
            {navItems.map((item) => (
              <div key={item.name} className="px-2 md:px-4 py-2 font-menu text-md">
                {
                  (item.to.includes("://") && <a href={item.to}>{item.name}</a>) || <Link to={item.to} getProps={isActive}>{item.name}</Link>
                }
              </div>
            ))
            }
          </nav>
        </header>
        <main className="font-serif">
          {children}
        </main>
        <footer>
        </footer>
      </div>
    </div>
  )
}

export default Layout;
